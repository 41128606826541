import React from 'react';
import Layout from '../components/layout';
import Chicken from '../../static/chicken.svg';
import { Hero, Container } from 'react-bulma-components';
import Media from 'react-media';
import ReCAPTCHA from "react-google-recaptcha";

class Contact extends React.Component{
  handleChange = value => {
    console.log("Captcha value:", value);
    
  };
    render(){
      const TEST_SITE_KEY = "6LfLWpkUAAAAACMj48TFIljr-gMM9d3cr0hcMDEm";
      
        return(
            <Layout title="Contact" hover='contact'>
            <Media query="(max-width:900px)">
                    {matches =>
                    matches ? (
                      <Container fluid className="mobileThatHolder">
              <div className="mobileChicken"><img src={Chicken} /></div>
                    <div className="mobileCircle"></div>
                    <div className="mobileTextSide mobileForm">
                    <p className="mobileThatLabel">Get in touch</p>
                      <form name="contact" className="thatMobileForm" method="POST" action="/.netlify/functions/career">
                     
                          <div className="field">
        
        
        <div className="control spef limitIt">
          <input class="input movingSection" name="email" type="email" placeholder="Email" />
        </div>
        
      </div>
      <div className="field">
      <div className="control spef limitIt">
          <input class="input movingSection" name="name" type="input" placeholder="Name" />
        </div>
      </div>
      
      <div className="field">
        
        <div className="control spaf limitIt">
          <textarea class="textarea movingSection" name="message" placeholder="Message" rows="1"></textarea>
        </div>
      </div>
      <div className="field captcha captchaMain">
      <div className="control">
      <ReCAPTCHA
            
            onChange={this.handleChange}
            sitekey={TEST_SITE_KEY}
            
            
          />
      
      </div>
      </div>
      
      <div className="field is-grouped">
        <div class="control spif">
          <button class="button is-link contactButton mobileButton movingSectionButton">Submit</button>
        </div>
        </div>
      

    
      </form>
                    </div>
                    
                    <blockquote className="mobileSecondTextSide blue">Connect with us</blockquote>
                    <blockquote className="mobileSecondTextSide blue">+961 81 424 444</blockquote>
                    <blockquote className="mobileSecondTextSide blue">+961 08 802 505</blockquote>
                    <blockquote className="mobileSecondTextSide blue">info@lebanonpoultry.com</blockquote>
              </Container>
            ) : (
              <Hero className="thatsecondHero" size="large">
                
                <Container fluid className="thatHolder2">
                <div className="circle2">
                <div className="thatForm">
                <p className="thatLabel">Get in touch</p>
                <form name="contact" method="POST" action="/.netlify/functions/career">
               
                    <div className="field">
  
  
  <div className="control spef">
    <input class="input" type="email" name="email" placeholder="Email" />
  </div>
  
</div>
<div className="field">
<div className="control spef">
    <input class="input" name="name" type="input" placeholder="Name" />
  </div>
</div>

<div className="field">
  
  <div className="control spaf">
    <textarea class="textarea" name="message" placeholder="Message" rows="1"></textarea>
  </div>
</div>
<div className="field">
<div className="control">
<ReCAPTCHA
            onChange={this.handleChange}
            sitekey={TEST_SITE_KEY}
            
            
          />
</div>
</div>

<div className="field is-grouped">
  <div class="control spif">
    <button class="button is-link contactButton">Submit</button>
  </div>
</div>
</form>
                </div>
                
                </div>
                <div className="chicken2"><img src={Chicken} /></div>
                <div className="circle3">
                    <div className="circle4">
                    <p className="thatP4">Connect with us</p>
                    <p className="thatP5">+961 81 424 444</p>
                    <p className="thatP5">+961 08 802 505 </p>
                    <p className="thatP5">info@lebanonpoultry.com</p>
                    </div>
                    
                </div>
                </Container>
                
                
            </Hero>
        

            )
          }
        </Media>
            
        </Layout>
        );
    }
}


export default Contact